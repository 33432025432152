import { useStaticQuery, graphql } from "gatsby";

export const useAllTeamRefereeStats = () => {
  const { allTeamRefereeStatsJson } = useStaticQuery(graphql`
    query {
      allTeamRefereeStatsJson {
        nodes {
          name
          slug
          referees {
            name
            points {
              away {
                opponent
                self
              }
              home {
                opponent
                self
              }
              total {
                opponent
                self
              }
            }
            redCards {
              away {
                opponent
                self
              }
              home {
                opponent
                self
              }
              total {
                opponent
                self
              }
            }
            yellowCards {
              away {
                opponent
                self
              }
              home {
                opponent
                self
              }
              total {
                opponent
                self
              }
            }
            matchCount {
              away
              home
              total
            }
          }
        }
      }
    }
  `);
  return allTeamRefereeStatsJson.nodes;
};
