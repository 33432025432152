import { useStaticQuery, graphql } from "gatsby";

export const useAllRefereeStats = () => {
  const { allRefereeStatsJson } = useStaticQuery(graphql`
  query {
    allRefereeStatsJson {
      nodes {
        slug
        name
        points {
          away
          home
        }
        redCards {
          away
          home
          total
        }
        teams {
          name
          points {
            away {
              opponent
              self
            }
            home {
              opponent
              self
            }
            total {
              opponent
              self
            }
          }
          matchCount {
            away
            home
            total
          }
          redCards {
            away {
              opponent
              self
            }
            home {
              opponent
              self
            }
            total {
              opponent
              self
            }
          }
          yellowCards {
            away {
              opponent
              self
            }
            home {
              opponent
              self
            }
            total {
              opponent
              self
            }
          }
        }
        yellowCards {
          away
          home
          total
        }
        matchCount
      }
    }
  }
  
  `);
  return allRefereeStatsJson.nodes;
};
