import React from "react";
import { FaAngleDown, FaAngleUp, FaArrowDown, FaArrowUp } from "react-icons/fa";

const ASCENDENDING = "asc";
const DESCENDENDING = "desc";

const SortableHeader = ({
  className,
  type,
  text,
  getSortDirection,
  onClick,
}) => {
  const sortDirection = getSortDirection(type);
  return (
    <div
      role="presentation"
      className={className}
      onClick={() => onClick(type)}
    >
      <div className="mr-1">{text}</div>
      <div>
        <div>
          <FaAngleUp
            className={`h-4 ${sortDirection === null ? "visible" : "hidden"}`}
          />
        </div>
        <div>
          <FaAngleDown
            className={`h-4 ${sortDirection === null ? "visible" : "hidden"}`}
          />
        </div>
        <div
          className={`mx-1 text-gray-800 h-full flex items-center ${
            sortDirection === DESCENDENDING ? "flex visible" : "hidden"
          }`}
        >
          <FaArrowDown className="h-8" />
        </div>
        <div
          className={`mx-1 text-gray-800 h-full flex items-center ${
            sortDirection === ASCENDENDING ? "flex visible" : "hidden"
          }`}
        >
          <FaArrowUp className="h-8" />
        </div>
      </div>
    </div>
  );
};

export default SortableHeader;
