import { useLocation } from '@reach/router';
import { navigate  } from 'gatsby';

function navigateTo(location, urlSearchParams, paramName, newValue) {
  if (newValue === undefined || newValue === null) {
    delete urlSearchParams[paramName];
  } else {
    urlSearchParams.set(paramName, newValue);
  }

  navigate(`${location.pathname}?${urlSearchParams.toString()}`);
}

export default function useQueryParams(paramName) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const paramValue = searchParams.get(paramName);

  const setQueryParam = (value, clearOthers=false) => {
    if (clearOthers) {
      navigateTo(location, new URLSearchParams(), paramName, value);
    } else {
      navigateTo(location, searchParams, paramName, value);
    }
  };

  return [paramValue, setQueryParam];
}
