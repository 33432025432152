import React from "react";

import SortableHeader from "./SortableHeader";
import useRefereeTableSorting from "../../hooks/useRefereeTableSorting";

const TeamByReferees = ({ teamStatistics }) => {
  const {
    sortedData: refereesStats,
    onSortableHeaderClick,
    getSortDirectionForSortType,
  } = useRefereeTableSorting(teamStatistics.referees);

  const valuesStyle =
    "text-xs justify-center text-center mx-1 flex items-center w-16 sm:w-20 lg:w-24 h-10";
  const hiddenValuesStyle = "hidden md:visible md:flex " + valuesStyle;
  const refereeNameStyle =
    "text-xs items-center flex grow w-24 h-10 ml-3 md:mr-4";
  const headerStyle = "font-bold cursor-pointer";
  return (
    <div className="flex flex-col w-full sm:w-11/12 m-auto max-w-7xl dark:text-white">
      <div className="flex flex-col border border-solid m-4">
        <div className="flex items-center h-16 bg-gray-200 dark:text-black">
          <SortableHeader
            type={["name"]}
            onClick={onSortableHeaderClick}
            text="Hakem"
            className={`${refereeNameStyle} ${headerStyle}`}
            getSortDirection={getSortDirectionForSortType}
          />
          <SortableHeader
            type={["matchCount", "total"]}
            onClick={onSortableHeaderClick}
            text="Maç"
            className={`${valuesStyle} ${headerStyle}`}
            getSortDirection={getSortDirectionForSortType}
          />
          <SortableHeader
            type={["yellowCards", "total", "self"]}
            onClick={onSortableHeaderClick}
            text="S.K."
            className={`${hiddenValuesStyle} ${headerStyle}`}
            getSortDirection={getSortDirectionForSortType}
          />
          <SortableHeader
            type={["yellowCards", "total", "opponent"]}
            onClick={onSortableHeaderClick}
            text="R.S.K."
            className={`${hiddenValuesStyle} ${headerStyle}`}
            getSortDirection={getSortDirectionForSortType}
          />
          <SortableHeader
            type={["redCards", "total", "self"]}
            onClick={onSortableHeaderClick}
            text="K.K."
            className={`${hiddenValuesStyle} ${headerStyle}`}
            getSortDirection={getSortDirectionForSortType}
          />
          <SortableHeader
            type={["redCards", "total", "opponent"]}
            onClick={onSortableHeaderClick}
            text="R.K.K."
            className={`${hiddenValuesStyle} ${headerStyle}`}
            getSortDirection={getSortDirectionForSortType}
          />
          <SortableHeader
            type={["points", "total", "self"]}
            onClick={onSortableHeaderClick}
            text="Puan"
            className={`${valuesStyle} ${headerStyle}`}
            getSortDirection={getSortDirectionForSortType}
          />
          <SortableHeader
            type={["points", "total", "opponent"]}
            onClick={onSortableHeaderClick}
            text="R.Puan"
            className={`${valuesStyle} ${headerStyle}`}
            getSortDirection={getSortDirectionForSortType}
          />
        </div>
        {refereesStats.map((team, index) => (
          <div
            className="flex items-center w-full border border-solid h-12"
            key={index}
          >
            <div className={`${refereeNameStyle} font-bold`}>{team.name}</div>
            <div className={valuesStyle}>{team.matchCount.total}</div>
            <div className={hiddenValuesStyle}>
              {team.yellowCards.total.self !== null
                ? team.yellowCards.total.self
                : "-"}
            </div>
            <div className={hiddenValuesStyle}>
              {team.yellowCards.total.opponent !== null
                ? team.yellowCards.total.opponent
                : "-"}
            </div>
            <div className={hiddenValuesStyle}>
              {team.redCards.total.self !== null
                ? team.redCards.total.self
                : "-"}
            </div>
            <div className={hiddenValuesStyle}>
              {team.redCards.total.opponent !== null
                ? team.redCards.total.opponent
                : "-"}
            </div>
            <div className={valuesStyle}>
              {team.points.total.self !== null ? team.points.total.self : "-"}
            </div>
            <div className={valuesStyle}>
              {team.points.total.opponent !== null
                ? team.points.total.opponent
                : "-"}
            </div>
          </div>
        ))}
      </div>
      <div className="w-full flex pl-4 flex-col gap-2 text-xs my-3">
        <div>S.K. - Sarı Kart</div>
        <div>K.K. - Kırmızı Kart</div>
        <div>R.S.K. - Rakip Sarı Kart</div>
        <div>R.K.K. - Rakip Kırmızı Kart</div>
        <div>R.Puan - Rakip Puan</div>
      </div>
    </div>
  );
};

export default TeamByReferees;
