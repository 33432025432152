import { useState } from "react";

const ASCENDENDING = "asc";
const DESCENDENDING = "desc";

const getSortValue = (data, sortType) => {
  let sortValue = data;

  for (let i = 0; i < sortType.length; i++) {
    sortValue = sortValue[sortType[i]];
  }
  if (sortValue === null || sortValue === undefined) return -1;
  else return sortValue;
};

const areBothValuesNotNullAndNotUndefined = (firstValue, secondValue) =>
  (firstValue !== null || firstValue !== undefined) &&
  (secondValue !== null || secondValue !== undefined);

export default function useRefereeTableSorting(data) {
  const onSortableHeaderClick = (sortType) => {
    if (JSON.stringify(sortingInfo.sortType) !== JSON.stringify(sortType)) {
      setSortingInfo({ sortType, sortDirection: DESCENDENDING });
    } else {
      const sortDirection =
        sortingInfo.sortDirection === DESCENDENDING
          ? ASCENDENDING
          : DESCENDENDING;
      setSortingInfo({ sortType, sortDirection });
    }
  };

  const getSortDirectionForSortType = (sortType) =>
    JSON.stringify(sortType) === JSON.stringify(sortingInfo.sortType)
      ? sortingInfo.sortDirection
      : null;

  const [sortingInfo, setSortingInfo] = useState({
    sortType: ["name"],
    sortDirection: ASCENDENDING,
  });

  const sortedData = [...data].sort((a, b) => {
    const firstValue =
      sortingInfo.sortDirection === ASCENDENDING
        ? getSortValue(a, sortingInfo.sortType)
        : getSortValue(b, sortingInfo.sortType);
    const secondValue =
      sortingInfo.sortDirection === ASCENDENDING
        ? getSortValue(b, sortingInfo.sortType)
        : getSortValue(a, sortingInfo.sortType);

    if (typeof firstValue === "number") {
      if (areBothValuesNotNullAndNotUndefined(firstValue, secondValue)) {
        const diff = firstValue - secondValue;
        return diff === 0 ? a.name.localeCompare(b.name) : diff;
      } else {
        return 1;
      }
    }

    if (areBothValuesNotNullAndNotUndefined(firstValue, secondValue)) {
      return firstValue.localeCompare(secondValue);
    }

    return 1;
  });

  return { sortedData, onSortableHeaderClick, getSortDirectionForSortType };
}
