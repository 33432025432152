import React from "react";

import SortableHeader from "./SortableHeader";
import useRefereeTableSorting from "../../hooks/useRefereeTableSorting";

const AllRefereesTeams = ({ refereeStatistics }) => {
  const {
    sortedData: refereesStats,
    onSortableHeaderClick,
    getSortDirectionForSortType,
  } = useRefereeTableSorting(refereeStatistics);

  const valuesStyle =
    "text-xs justify-center text-center mx-1 flex items-center w-16 lg:w-24 h-10";
  const hiddenValuesStyle = "hidden md:visible md:flex " + valuesStyle;
  const refereeNameStyle =
    "text-xs items-center flex grow w-28 h-10 ml-3 md:mr-4";
  const headerStyle = "font-bold cursor-pointer";
  return (
    <div className="flex flex-col w-full sm:w-11/12 m-auto max-w-7xl dark:text-white">
      <div className="flex flex-col border border-solid m-4">
        <div className="flex items-center bg-gray-50 h-16 dark:text-black">
          <SortableHeader
            type={["name"]}
            onClick={onSortableHeaderClick}
            text="Hakem"
            className={`${refereeNameStyle} ${headerStyle}`}
            getSortDirection={getSortDirectionForSortType}
          />
          <SortableHeader
            type={["matchCount"]}
            onClick={onSortableHeaderClick}
            text="Maç"
            className={`${valuesStyle} ${headerStyle}`}
            getSortDirection={getSortDirectionForSortType}
          />
          <SortableHeader
            type={["yellowCards", "total"]}
            onClick={onSortableHeaderClick}
            text="S.K."
            className={`${valuesStyle} ${headerStyle}`}
            getSortDirection={getSortDirectionForSortType}
          />
          <SortableHeader
            type={["redCards", "total"]}
            onClick={onSortableHeaderClick}
            text="K.K."
            className={`${valuesStyle} ${headerStyle}`}
            getSortDirection={getSortDirectionForSortType}
          />
          <SortableHeader
            type={["yellowCards", "home"]}
            onClick={onSortableHeaderClick}
            text="Ev S.K."
            className={`${hiddenValuesStyle} ${headerStyle}`}
            getSortDirection={getSortDirectionForSortType}
          />
          <SortableHeader
            type={["yellowCards", "away"]}
            onClick={onSortableHeaderClick}
            text="Dep S.K."
            className={`${hiddenValuesStyle} ${headerStyle}`}
            getSortDirection={getSortDirectionForSortType}
          />
          <SortableHeader
            type={["redCards", "home"]}
            onClick={onSortableHeaderClick}
            text="Ev K.K."
            className={`${hiddenValuesStyle} ${headerStyle}`}
            getSortDirection={getSortDirectionForSortType}
          />
          <SortableHeader
            type={["redCards", "away"]}
            onClick={onSortableHeaderClick}
            text="Dep K.K."
            className={`${hiddenValuesStyle} ${headerStyle}`}
            getSortDirection={getSortDirectionForSortType}
          />
          <SortableHeader
            type={["points", "home"]}
            onClick={onSortableHeaderClick}
            text="Ev Puan"
            className={`${valuesStyle} ${headerStyle}`}
            getSortDirection={getSortDirectionForSortType}
          />
          <SortableHeader
            type={["points", "away"]}
            onClick={onSortableHeaderClick}
            text="Dep Puan"
            className={`${valuesStyle} ${headerStyle}`}
            getSortDirection={getSortDirectionForSortType}
          />
        </div>
        {refereesStats.map((referee, index) => (
          <div
            className="flex items-center w-full border border-solid h-12"
            key={index}
          >
            <div className={`${refereeNameStyle} font-bold`}>
              {referee.name}
            </div>
            <div className={valuesStyle}>{referee.matchCount}</div>
            <div className={valuesStyle}>{referee.yellowCards.total}</div>
            <div className={valuesStyle}>{referee.redCards.total}</div>
            <div className={hiddenValuesStyle}>{referee.yellowCards.home}</div>
            <div className={hiddenValuesStyle}>{referee.yellowCards.away}</div>
            <div className={hiddenValuesStyle}>{referee.redCards.home}</div>
            <div className={hiddenValuesStyle}>{referee.redCards.away}</div>
            <div className={valuesStyle}>{referee.points.home}</div>
            <div className={valuesStyle}>{referee.points.away}</div>
          </div>
        ))}
      </div>
      <div className="w-full flex pl-4 flex-col gap-2 text-xs my-3">
        <div>S.K. - Sarı Kart</div>
        <div>K.K. - Kırmızı Kart</div>
        <div>Dep. - Deplasman</div>
      </div>
    </div>
  );
};

export default AllRefereesTeams;
