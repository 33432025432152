import React, { useMemo } from "react";

import AllRefereesTeams from "../components/referee-tables/AllRefereesTeams";
import Combobox from "../components/Combobox";
import { SeoComponent } from "../components/SeoComponent";
import RefereeByTeams from "../components/referee-tables/RefereeByTeams";
import TeamByReferees from "../components/referee-tables/TeamByReferees";
import { useAllRefereeStats } from "../hooks/useAllRefereeStats";
import { useAllTeamRefereeStats } from "../hooks/useAllTeamRefereeStats";
import useQueryParams from "../hooks/useQueryParams";
import { slugToTeamMap } from "../util/teamUtil";

const allTeamsText = "Tüm Takımlar";
const allRefereesText = "Tüm Hakemler";

const RefereeStatsTable = ({
  teamStatistics,
  refereeStatistics,
  selectedReferee,
  selectedTeam,
}) => {
  let RefereeTable;
  if (selectedReferee !== allRefereesText) {
    RefereeTable = (
      <RefereeByTeams
        refereeStatistics={refereeStatistics.find(
          (referee) => referee.name === selectedReferee
        )}
      />
    );
  } else if (selectedTeam !== allTeamsText) {
    RefereeTable = (
      <TeamByReferees
        teamStatistics={teamStatistics.find(
          (team) => team.name === selectedTeam
        )}
      />
    );
  } else {
    RefereeTable = <AllRefereesTeams refereeStatistics={refereeStatistics} />;
  }

  return RefereeTable;
};

const Refereees = () => {
  const allTeamReferees = useAllTeamRefereeStats();
  const allReferees = useAllRefereeStats();

  const slugToReferee = useMemo(() => {
    const result = {};
    allReferees.forEach((referee) => {
      result[referee.slug] = referee.name;
    });
    return result;
  }, [allReferees]);

  const refereeNames = [
    allRefereesText,
    ...Object.values(slugToReferee).sort((a, b) => a.localeCompare(b)),
  ];
  const teamNames = [
    allTeamsText,
    ...Object.values(slugToTeamMap).sort((a, b) => a.localeCompare(b)),
  ];
  const realDataExists = refereeNames[1] !== "no-name";

  const [refereeName, setRefereeName] = useQueryParams("hakem");
  const [teamName, setTeamName] = useQueryParams("takim");
  const selectedTeam = slugToTeamMap[teamName] || allTeamsText;
  const selectedReferee =
    selectedTeam === allTeamsText
      ? slugToReferee[refereeName] || allRefereesText
      : allRefereesText;

  const updateSelectedReferee = (name) => {
    const slug = Object.keys(slugToReferee).filter(
      (key) => slugToReferee[key] === name
    )[0];
    setRefereeName(slug, true);
  };

  const updateSelectedTeam = (name) => {
    const slug = Object.keys(slugToTeamMap).filter(
      (key) => slugToTeamMap[key] === name
    )[0];
    setTeamName(slug, true);
  };

  return (
    <div className="w-full gap-3 flex flex-col items-center pb-4 dark:text-white">
      <h1 className="text-pink-500 font-bold text-center lg:mt-0 mt-4">
        Süper Lig Hakem İstatistikleri
      </h1>
      {realDataExists ? (
        <>
          <div className="flex justify-center">
            <div className="flex flex-col justify-center">
              <div className="font-bold w-full text-xs flex justify-center">
                Hakemler
              </div>
              <Combobox
                data={refereeNames}
                selected={selectedReferee}
                onSelect={updateSelectedReferee}
                width={48}
              />
            </div>
            <div className="flex flex-col justify-center">
              <div className="font-bold w-full text-xs flex justify-center">
                Takımlar
              </div>
              <Combobox
                data={teamNames}
                selected={selectedTeam}
                onSelect={updateSelectedTeam}
                width={48}
              />
            </div>
          </div>
          <RefereeStatsTable
            refereeStatistics={allReferees}
            teamStatistics={allTeamReferees}
            selectedReferee={selectedReferee}
            selectedTeam={selectedTeam}
          />
        </>
      ) : (
        <div>Yeni sezon istatistikleri ilk hafta bitince...</div>
      )}
    </div>
  );
};

export default Refereees;

export const Head = () => (
  <SeoComponent
    title="Süper Lig Hakemleri"
    description="Türkiye Futbol Süper Ligi 2024-25 Sezonu Hakemleri: Ali Şansalan, Turgut Doman, Ümit Öztürk, Halil Umut Meler, Mert Güzenge, Kadir Sağlam, Tugay Kaan Numanoğlu, Direnç Tonusluoğlu, Atilla Karaoğlan, Cihan Aydın, Arda Kardeşler, Erkan Özdamar, Yaşar Kemal Uğurlu, Zorbay Küçük, Yasin Kol, Çağdaş Altay, Burak Şeker, Sarperbarış Saka, Mete Kalkavan, Bahattin Şimşek, Abdülkadir Bitigen, Volkan Bayarslan, Murat Erdoğan, Yiğit Arslan, Burak Pakkan, Süperlig, Superlig, ensuperlig, ensüperlig"
  />
);
